import { axios } from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchBanners() {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/v1/admin/website/banners')
          .then(response => (resolve(response)))
          .catch(error => reject(error))
      })
    },
    fetchBanner(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/v1/admin/website/banners/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    createBanner(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/v1/admin/website/banners', params.banner)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateBanner(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/api/v1/admin/website/banners/${params.id}`, params.banner)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteBanner(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/api/v1/admin/website/banners/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
