<template>
  <div>
    <v-card>
      <v-card-text class="d-flex align-center flex-wrap pb-0">
        <div class="d-flex align-center pb-5">
          <!-- create banner -->
          <v-btn
            color="primary"
            class="me-3"
            @click="isSaveDialogVisible = true"
          >
            <v-icon
              size="18"
              class="me-1"
            >
              {{ icons.mdiPlus }}
            </v-icon>
            <span>{{ t('Create Banner') }}</span>
          </v-btn>

          <span>Make sure that your image is sized 1280px x 720px and not more than 5MB in size.</span>
        </div>
      </v-card-text>
    </v-card>

    <div class="mt-5">
      <v-row v-if="banners.length > 0">
        <v-col
          v-for="banner in banners"
          :key="banner.id"
          md="4"
          cols="12"
        >
          <v-card>
            <v-img
              :src="banner.image_url"
            />
            <v-card-title>
              {{ banner.title }}
            </v-card-title>
            <v-card-text>
              <p>
                <b>Uploaded by:</b> {{ banner.uploaded_by }}
              </p>
            </v-card-text>
            <v-card-actions>
              <v-btn
                color="error"
                @click="isDeleteDialogVisible = true; localBanner = banner"
              >
                Delete
              </v-btn>
              <v-btn
                color="primary"
                @click="isSaveDialogVisible = true; fetchBanner(banner.id)"
              >
                Edit
              </v-btn>
              <v-spacer></v-spacer>
              <v-chip
                v-if="banner.is_active"
                label
                color="success"
                class="v-chip-light-bg success--text"
              >
                Active
              </v-chip>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
      <div
        v-else
        class="text-center"
      >
        No banners uploaded yet!
      </div>
    </div>

    <!-- Save dialog -->
    <v-dialog
      v-model="isSaveDialogVisible"
      width="500"
      persistent
    >
      <v-card>
        <v-card-title class="d-flex align-center mv-4 mb-4">
          {{ localBanner.id ? 'Edit' : 'New' }} Banner
          <v-spacer></v-spacer>
          <v-btn
            icon
            small
            @click="isSaveDialogVisible = false; saveForm.reset(); localBanner = {}, selectedFile = null"
          >
            <v-icon size="22">
              {{ icons.mdiClose }}
            </v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          <v-form
            ref="saveForm"
            v-model="saveValid"
          >
            <v-text-field
              v-model="localBanner.title"
              outlined
              dense
              label="Title"
              :rules="[validators.required]"
              hide-details="auto"
              class="mb-6"
            ></v-text-field>
            <v-row>
              <v-col
                cols="12"
                md="3"
              >
                <v-text-field
                  v-model="localBanner.order"
                  outlined
                  dense
                  label="Order"
                  type="number"
                  hide-details="auto"
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                md="9"
              >
                <v-switch
                  v-model="localBanner.is_active"
                  inset
                  :label="localBanner.is_active ? 'This banner is now visible.' : 'This banner is now hidden.'"
                  hide-details
                ></v-switch>
              </v-col>
            </v-row>
            <v-img
              :src="localBanner.image_url"
              :aspect-ratio="16/9"
              width="auto"
              class="mx-auto mt-4 banner-image hover-pointer"
              @click="$refs.refInputEl.click()"
            ></v-img>
            <p class="my-3">
              Click the space above to upload new promotional banner
            </p>
            <input
              ref="refInputEl"
              type="file"
              accept=".jpeg,.png,.jpg"
              hidden
              :rules="[validators.required]"
              @change="onImageUpload($event)"
            />
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-btn
            color="primary"
            block
            :loading="saveLoading"
            :disabled="saveLoading || !saveValid"
            @click="saveBanner"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Cropper dialog -->
    <v-dialog
      v-model="isCropperDialogVisible"
      width="500"
    >
      <v-card class="pa-3">
        <VueCropper
          v-if="selectedFile"
          ref="CropperRef"
          class="cropper-height"
          :aspect-ratio="16/9"
          :src="selectedFile"
        ></VueCropper>
        <v-sheet v-else>
          <v-skeleton-loader type="image" />
        </v-sheet>
        <v-card-actions class="px-0 pb-0 pt-3">
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            outlined
            class="me-3"
            @click="isCropperDialogVisible = false"
          >
            Cancel
          </v-btn>
          <v-btn
            depressed
            class="primary"
            :loading="cropLoading"
            :disabled="cropLoading"
            @click="cropImage(), isCropperDialogVisible = false"
          >
            Crop
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="isDeleteDialogVisible"
      width="500"
      persistent
    >
      <v-card>
        <v-card-title class="d-flex align-center mv-4">
          Delete {{ localBanner.name }}?
          <v-spacer></v-spacer>
          <v-btn
            icon
            small
            @click="isDeleteDialogVisible = false; localBanner = {}"
          >
            <v-icon size="22">
              {{ icons.mdiClose }}
            </v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          This banner will be removed from this list. It will no longer be visible to the public and staff members.
        </v-card-text>

        <v-card-actions>
          <v-btn
            color="error"
            block
            :loading="deleteLoading"
            :disabled="deleteLoading"
            class="mt-3"
            @click="deleteBanner(localBanner.id)"
          >
            Yes, remove
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {
  mdiPlus,
  mdiClose,
  mdiCloudUploadOutline,
} from '@mdi/js'
import {
  onMounted,
  onUnmounted,
  ref,
  inject,
} from '@vue/composition-api'
import { required } from '@core/utils/validation'
import VueCropper from 'vue-cropperjs'
import websiteStoreModule from './websiteStoreModule'
import 'cropperjs/dist/cropper.css'

export default {
  components: {
    VueCropper,
  },
  setup() {
    // Initializers
    const WEBSITE_APP_STORE_MODULE_NAME = 'app-website'
    const store = inject('store')
    const snackbarService = inject('snackbarService')
    const t = inject('t')
    const CropperRef = ref(null)
    const selectedFile = ref('')

    if (!store.hasModule(WEBSITE_APP_STORE_MODULE_NAME)) {
      store.registerModule(WEBSITE_APP_STORE_MODULE_NAME, websiteStoreModule)
    }

    // Properties
    const saveForm = ref(null)
    const saveValid = ref(false)
    const banners = ref([])
    const localBanner = ref({
      is_active: false,
    })
    const isSaveDialogVisible = ref(false)
    const saveLoading = ref(false)
    const cropLoading = ref(false)
    const deleteLoading = ref(false)
    const isCropperDialogVisible = ref(false)
    const isDeleteDialogVisible = ref(false)

    // Methods
    const fetchBanners = () => {
      store
        .dispatch('app-website/fetchBanners')
        .then(response => {
          banners.value = response.data.data
        })
        .catch(error => {
          snackbarService.error(error.response.data.message || 'Something went wrong while fetching banners. Please refresh!')
        })
    }
    const fetchBanner = id => {
      store
        .dispatch('app-website/fetchBanner', { id })
        .then(response => {
          localBanner.value = response.data.data
        })
        .catch(error => {
          snackbarService.error(error.response.data.message || 'Something went wrong while fetching banner. Please refresh!')
        })
    }
    const saveBanner = () => {
      saveLoading.value = true
      const formData = new FormData()
      Object.entries(localBanner.value).forEach(([key, value]) => {
        if (value || key === 'is_active') formData.append(key, value)
      })

      store
        .dispatch(`app-website/${localBanner.value.id ? 'updateBanner' : 'createBanner'}`, { id: localBanner.value.id, banner: formData })
        .then(response => {
          snackbarService.success(response.data.message)
          fetchBanners()
          saveLoading.value = false
          saveForm.value.reset()
          isSaveDialogVisible.value = false
          localBanner.value = {}
        })
        .catch(error => {
          saveLoading.value = false
          snackbarService.error(error.data.message || 'Something went wrong while saving banner. Please refresh!')
        })
    }
    const onImageUpload = event => {
      const file = event.target.files[0]
      if (!/\.(jpg|jpeg|png|JPG|PNG)$/.test(event.target.value)) {
        snackbarService.error('The file type must be one of jpeg, jpg or png.')

        return
      }
      if (file.size / 1024 / 1024 > 5) {
        snackbarService.error('Please select a file that is less than 5MB.')

        return
      }
      if (typeof FileReader !== 'function') {
        snackbarService.error('Sorry, FileReader API not supported. Please try a different browser.')

        return
      }

      isCropperDialogVisible.value = true
      const reader = new FileReader()
      reader.onload = content => {
        selectedFile.value = content.target.result
        if (CropperRef.value) CropperRef.value.replace(selectedFile.value)
      }
      reader.readAsDataURL(file)
    }
    const cropImage = async () => {
      cropLoading.value = true
      localBanner.value.image_url = CropperRef.value.getCroppedCanvas().toDataURL()
      await CropperRef.value.getCroppedCanvas().toBlob(blob => {
        localBanner.value.image = blob
        cropLoading.value = false
      })
    }
    const deleteBanner = id => {
      deleteLoading.value = true
      store
        .dispatch('app-website/deleteBanner', { id })
        .then(response => {
          snackbarService.success(response.data.message)
          fetchBanners()
          isDeleteDialogVisible.value = false
          deleteLoading.value = false
          localBanner.value = {}
        })
        .catch(error => {
          deleteLoading.value = false
          snackbarService.error(error.data.message || 'Something went wrong while deleting banner. Please refresh!')
        })
    }

    // Mounted
    onMounted(() => fetchBanners())

    // Unmounted
    onUnmounted(() => {
      if (store.hasModule(WEBSITE_APP_STORE_MODULE_NAME)) store.unregisterModule(WEBSITE_APP_STORE_MODULE_NAME)
    })

    return {
      t,
      saveForm,
      saveValid,
      banners,
      localBanner,
      fetchBanners,
      isSaveDialogVisible,
      saveLoading,
      fetchBanner,
      saveBanner,
      CropperRef,
      selectedFile,
      cropLoading,
      isCropperDialogVisible,
      onImageUpload,
      cropImage,
      deleteBanner,
      isDeleteDialogVisible,
      deleteLoading,
      validators: {
        required,
      },
      icons: {
        mdiPlus,
        mdiClose,
        mdiCloudUploadOutline,
      },
    }
  },
}
</script>

<style lang="scss" scoped>
  .v-input--selection-controls {
    margin-top: 0 !important;
    padding-top: 8px !important;
  }

  .banner-image {
    border: 1px dashed rgba(231, 227, 252, 0.87);
    border-radius: 4px;
  }
</style>
